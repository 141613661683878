<template>
  <v-expansion-panels
    variant="accordion"
    :value="data.open_by_default ? 0 : undefined"
  >
    <v-expansion-panel>
      <v-expansion-panel-header>{{
        data.panel_title
      }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <span class="text-caption">Start</span>
        <v-select
          data-testid="journey-start-location"
          v-model="startLocation"
          :items="locations"
          item-text="title"
          placeholder="Select Location"
          return-object
        >
        </v-select>
        <v-select
          data-testid="journey-start-entrance"
          v-model="entrance"
          :disabled="startLocation === null"
          :items="entrances"
          placeholder="Choose an entrance"
        >
        </v-select>
        <span class="text-caption">Stop</span>
        <v-select
          data-testid="journey-stop-location"
          :disabled="startLocation === null"
          v-model="stopLocation"
          item-text="title"
          :items="stops"
          placeholder="Select Location"
          return-object
        >
        </v-select>
        <v-select
          data-testid="journey-stop-entrance"
          v-model="exit"
          :disabled="stopLocation === null"
          :items="exits"
          placeholder="Choose an exit"
        >
        </v-select>
        <div
          :style="{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '10px',
            paddingTop: '10px',
          }"
        >
          <v-btn-toggle v-model="toggle" divided mandatory>
            <v-btn value="360" data-testid="video360-option">360 Journey Videos</v-btn>
            <v-btn value="2D" data-testid="video2D-option">Journey Videos</v-btn>
          </v-btn-toggle>
        </div>

        <v-btn
          :disabled="!enableJourney"
          :style="{ marginTop: '10px' }"
          block
          @click="showJourney()"
          data-testid="journey-btn"
        >
          <span> Experience Journey </span>
        </v-btn>
        <v-btn
          :disabled="!hasSelectedLocation"
          :style="{ marginTop: '10px' }"
          block
          @click="handleReset()"
        >
          Reset
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import LightboxGallery from "../lightbox/LightboxGallery.vue";

const journeyPlannerChildrenType = Object.freeze({
  LOCATION: "location",
  JOURNEY: "journey",
});
const branchingVideoType = Object.freeze({
  ENTRANCE: "entrance",
  JOURNEY: "journey",
  EXIT: "exit",
});

function findMatchingJourney(vue) {
  const locationPoints = vue.data.children.filter(
    (item) => item.type === journeyPlannerChildrenType.LOCATION
  );
  const currentStartLocation = locationPoints.find(
    (item) => item.value.title === vue.startLocation
  );
  const currentStopLocation = locationPoints.find(
    (item) => item.value.title === vue.stopLocation
  );

  let journeyKeyPair = [];
  journeyKeyPair.push(currentStartLocation.value.journey_key_id);
  journeyKeyPair.push(currentStopLocation.value.journey_key_id);

  // Find any matching journeys
  const journeys = vue.data.children.filter(
    (item) => item.type === journeyPlannerChildrenType.JOURNEY
  );

  const journey = journeys.find(
    (journey) =>
      journey.value.journey_key_pair.toString() === journeyKeyPair.toString()
  );
  return journey;
}

function updateMapWithJourney(vue, journey) {
  
  // Clear the current map layers
  vue.$store.dispatch("wagtail/updateMapLayerOptions", {
    key: vue.activeJourneyLayerID,
    data: { [vue.activeJourneyLayer]: false },
  });

  vue.activeJourneyLayer = journey.value.layer_name;
  vue.activeJourneyLayerID = vue._uid;
  
  // Wait for the render then update the map with intended layers
  vue.$nextTick(function () {
    let payload = {
      key: vue.activeJourneyLayerID,
      data: { [vue.activeJourneyLayer]: true },
    };

    vue.$store.dispatch("wagtail/updateMapLayerOptions", payload);
   
  });
}

export default {
  components: {
    LightboxGallery,
  },
  props: ["data"],
  data() {
    return {
      enableJourney: false,
      toggle: "2D",
      startLocation: null,
      stopLocation: null,
      entrance: null,
      exit: null,
      locations: [],
      entrances: [],
      exits: [],
      stops: [],
      branchingVideos: [
        { type: "entrance", video: {}, video2D: {} },
        { type: "exit", video: {}, video2D: {} },
      ],
      journey: null,
      activeJourneyLayer: null,
      activeJourneyLayerID: null,
      branchingVideoContent: [{}],
      branchingVideo2DContent: [{}],
      showBranchingVideos: false,
      panel: [0, 1],
      disabled: false,
    };
  },

  mounted() {
    const locations = this.data.children.filter(
      (item) => item.type === journeyPlannerChildrenType.LOCATION
    );
    this.locations = locations.map((item) => item.value.title);
  },
  computed: {
    accessibilityMode() {
      return this.$store.state.wagtail.accessibilityMode;
    },
    hasSelectedLocation() {
      return (
        this.startLocation || this.stopLocation || this.entrance || this.exit
      );
    },
    resetJourney() {
      return this.$store.state.map.resetJourney;
    },
  },
  watch: {
    resetJourney(value) {
      if (value) {
        this.handleReset();
        this.$store.commit("map/setResetJourney", false);
      }
    },
    startLocation(newValue, oldValue) {
      if (newValue) {
        const locations = this.data.children.filter(
          (item) => item.type === journeyPlannerChildrenType.LOCATION
        );
        const currentLocation = locations.find(
          (item) => item.value.title === newValue
        );
        this.entrances = currentLocation.value.sub_children.map(
          (item) => item.poi_title
        );

        // Reset these values when a location is chosen
        this.entrance = null;
        this.exit = null;
        this.journey = null;
        this.enableJourney = false;
        this.branchingVideoContent = [];
        this.branchingVideo2DContent = [];

        let stopLocations = [...locations];
        stopLocations = stopLocations.filter(
          (item) => item.value.title !== this.startLocation
        );
        stopLocations = stopLocations.map((item) => item.value.title);
        this.stops = stopLocations;

        if (this.startLocation != null && this.stopLocation != null) {
          if (this.startLocation === this.stopLocation) {
            this.$store.dispatch("wagtail/updateMapLayerOptions", {
              key: this.activeJourneyLayerID,
              data: { [this.activeJourneyLayer]: false },
            });
            return;
          }

          const journey = findMatchingJourney(this);
          if (journey) updateMapWithJourney(this, journey);
        }
      }
    },

    stopLocation(newValue, oldValue) {
      if (newValue) {
        const locations = this.data.children.filter(
          (item) => item.type === journeyPlannerChildrenType.LOCATION
        );
        const currentLocation = locations.find(
          (item) => item.value.title === newValue
        );
        this.exits = currentLocation.value.sub_children.map(
          (item) => item.poi_title
        );

        // Reset these values when a location is chosen
        this.exit = null;
        this.journey = null;
        this.enableJourney = false;
        this.branchingVideoContent = [];
        this.branchingVideo2DContent = [];

        if (this.startLocation != null && this.stopLocation != null) {
          const journey = findMatchingJourney(this);
          if (journey) {
            updateMapWithJourney(this, journey);
          } else {
            this.$store.dispatch("wagtail/updateMapLayerOptions", {
              key: this.activeJourneyLayerID,
              data: { [this.activeJourneyLayer]: false },
            });
          }
        }
      }
    },

    entrance(newValue, oldValue) {
      if (!newValue) return;

      // Reset these values when a Point of Interest is chosen
      this.journey = null;
      this.enableJourney = false;
      this.branchingVideoContent = [];
      this.branchingVideo2DContent = [];

      // Find video to add
      let video = null;
      let videoAccessibility = null;
      let video2D = null;
      let video2DAccessibility = null;
      const locations = this.data.children.filter(
        (item) => item.type === journeyPlannerChildrenType.LOCATION
      );
      const currentLocation = locations.find(
        (item) => item.value.title === this.startLocation
      );
      const poi = currentLocation.value.sub_children.find(
        (item) => item.poi_title === newValue
      );

      video = poi.start_video.video ? poi.start_video : null;
      videoAccessibility = poi.start_accessible_video.video
        ? poi.start_accessible_video
        : null;
      video2D = poi.start_video_2D;
      video2DAccessibility = poi.start_accessible_video_2D;

      // Reconstruct the branching videos
      let newVideos = [];
      newVideos.push({
        type: branchingVideoType.ENTRANCE,
        video: video,
        videoAccessibility: videoAccessibility,
        video2D: video2D,
        video2DAccessibility: video2DAccessibility,
      });

      // Readd the exit if any
      for (let i = 0; i < this.branchingVideos.length; i++) {
        if (this.branchingVideos[i].type === branchingVideoType.EXIT) {
          newVideos.push(this.branchingVideos[i]);
        }
      }

      // Add / Readd possible journey
      if (this.entrance !== null && this.exit !== null) {
        // Get keys of the locations
        const locationPoints = this.data.children.filter(
          (item) => item.type === journeyPlannerChildrenType.LOCATION
        );
        const currentStartLocation = locationPoints.find(
          (item) => item.value.title === this.startLocation
        );
        const currentStopLocation = locationPoints.find(
          (item) => item.value.title === this.stopLocation
        );

        let journeyKeyPair = [];
        journeyKeyPair.push(currentStartLocation.value.journey_key_id);
        journeyKeyPair.push(currentStopLocation.value.journey_key_id);

        // Find any matching journeys
        const journeys = this.data.children.filter(
          (item) => item.type === journeyPlannerChildrenType.JOURNEY
        );
        let journeyMatch = false;
        for (const journey of journeys) {
          if (
            journey.value.journey_key_pair.toString() ===
            journeyKeyPair.toString()
          ) {
            journeyMatch = true;
            let video = journey.value.video_picker.video
              ? journey.value.video_picker
              : null;
            let videoAccessibility = journey.value.accessible_video_picker.video
              ? journey.value.accessible_video_picker
              : null;
            let video2D = journey.value.accessible_video_picker_2D;
            let video2DAccessibility = journey.value.accessible_video_picker_2D;

            newVideos.push({
              type: branchingVideoType.JOURNEY,
              video: video,
              videoAccessibility: videoAccessibility,
              video2D: video2D,
              video2DAccessibility: video2DAccessibility,
            });
            this.journey = journey;
          } else {
            if (journeyMatch == false) {
              this.journey = null;
            }
          }
        }
      }
      this.branchingVideos = newVideos;
    },
    exit(newValue, oldValue) {
      if (!newValue) return;

      // Reset these values when a Point of Interest is chosen
      this.journey = null;
      this.enableJourney = false;
      this.branchingVideoContent = [];
      this.branchingVideo2DContent = [];

      // Find video to add
      let video = null;
      let videoAccessibility = null;
      let video2D = null;
      let video2DAccessibility = null;
      const locations = this.data.children.filter(
        (item) => item.type === journeyPlannerChildrenType.LOCATION
      );
      const currentLocation = locations.find(
        (item) => item.value.title === this.stopLocation
      );
      const poi = currentLocation.value.sub_children.find(
        (item) => item.poi_title === newValue
      );

      video = poi.destination_video.video ? poi.destination_video : null;
      videoAccessibility = poi.destination_accessible_video.video
        ? poi.destination_accessible_video
        : null;
      video2D = poi.destination_video_2D;
      video2DAccessibility = poi.destination_accessible_video_2D;

      // Reconstruct the branching videos
      let newVideos = [];
      newVideos.push({
        type: branchingVideoType.EXIT,
        video: video,
        videoAccessibility,
        video2D: video2D,
        video2DAccessibility,
      });

      // Readd the Entrance video
      for (let i = 0; i < this.branchingVideos.length; i++) {
        if (this.branchingVideos[i].type === branchingVideoType.ENTRANCE) {
          newVideos.push(this.branchingVideos[i]);
        }
      }

      // Add / Readd possible journey
      if (this.entrance !== null && this.exit !== null) {
        // Get keys of the locations
        const locationPoints = this.data.children.filter(
          (item) => item.type === journeyPlannerChildrenType.LOCATION
        );
        const currentStartLocation = locationPoints.find(
          (item) => item.value.title === this.startLocation
        );
        const currentStopLocation = locationPoints.find(
          (item) => item.value.title === this.stopLocation
        );

        let journeyKeyPair = [];
        journeyKeyPair.push(currentStartLocation.value.journey_key_id);
        journeyKeyPair.push(currentStopLocation.value.journey_key_id);

        // Find any matching journeys
        const journeys = this.data.children.filter(
          (item) => item.type === journeyPlannerChildrenType.JOURNEY
        );
        let journeyMatch = false;
        for (const journey of journeys) {
          if (
            journey.value.journey_key_pair.toString() ===
            journeyKeyPair.toString()
          ) {
            journeyMatch = true;
            let video = journey.value.video_picker.video
              ? journey.value.video_picker
              : null;
            let videoAccessibility = journey.value.accessible_video_picker.video
              ? journey.value.accessible_video_picker
              : null;
            let video2D = journey.value.video_picker_2D;
            let video2DAccessibility = journey.value.accessible_video_picker_2D;

            newVideos.push({
              type: branchingVideoType.JOURNEY,
              video: video,
              videoAccessibility: videoAccessibility,
              video2D: video2D,
              video2DAccessibility: video2DAccessibility,
            });
            this.journey = journey;
          } else {
            if (journeyMatch == false) {
              this.journey = null;
            }
          }
        }
      }

      this.branchingVideos = newVideos;
    },
    journey(newValue, oldValue) {
      if (!newValue) return;
      
      let journeyVideos = [];
      let journeyVideos2D = [];
      const startingVideo = this.branchingVideos.find(
        (item) => item.type === branchingVideoType.ENTRANCE
      );
      const journeyVideo = this.branchingVideos.find(
        (item) => item.type === branchingVideoType.JOURNEY
      );
      const endingVideo = this.branchingVideos.find(
        (item) => item.type === branchingVideoType.EXIT
      );

      journeyVideos.push(startingVideo ?? "");
      journeyVideos.push(journeyVideo ?? "");
      journeyVideos.push(endingVideo ?? "");

      journeyVideos2D.push(startingVideo ?? "");
      journeyVideos2D.push(journeyVideo ?? "");
      journeyVideos2D.push(endingVideo ?? "");

      const content = [
        { type: "branching_video_360", value: { videos: journeyVideos } },
      ];

      const content2D = [
        { type: "branching_video", value: { videos: journeyVideos2D } },
      ];
      this.branchingVideoContent = content;
      this.branchingVideo2DContent = content2D;

      if (this.toggle === "360") {
        if (!journeyVideos.includes("")) {
          this.enableJourney = true;
        }
      } else if (this.toggle === "2D") {
        if (!journeyVideos2D.includes("")) {
          this.enableJourney = true;
        }
      }
    },
    toggle(newValue, oldValue) {

      /* Checks which type of the first video is, to enable/disable the journey button */
      if (newValue === "360") {
        if (
          this.branchingVideoContent.length > 0 &&
          this.branchingVideoContent[0].value?.videos[0].video
        ) {
          this.enableJourney = true;
        } else {
          this.enableJourney = false;
        }
      } else if (newValue === "2D") {
        if (
          this.branchingVideo2DContent.length > 0 &&
          this.branchingVideo2DContent[0].value?.videos[0].video2D
        ) {
          this.enableJourney = true;
        } else {
          this.enableJourney = false;
        }
      }
    },
  },
  methods: {
    handleReset() {
      // Reset Map Layers
      this.$store.dispatch("wagtail/updateMapLayerOptions", {
        key: this.activeJourneyLayerID,
        data: { [this.activeJourneyLayer]: false },
      });

      this.startLocation = null;
      this.stopLocation = null;
      this.entrance = null;
      this.exit = null;
      this.activeJourneyLayer = null;
      this.activeJourneyLayerID = null;
      this.branchingVideos = [
        { type: "entrance", video: {}, video2D: {} },
        { type: "exit", video: {}, video2D: {} },
      ];
      this.journey = null;
      this.branchingVideoContent = [{}];
      this.branchingVideo2DContent = [{}];
      this.enableJourney = false;
      this.showBranchingVideos = false;
      this.toggle = "2D";
    },
    showJourney() {
      const content =
        this.toggle === "360"
          ? this.branchingVideoContent
          : this.branchingVideo2DContent;
      this.$store.commit("map/setExperienceJourney", true);
      this.$store.commit("map/setShowJourneyVideos", true);
      this.$store.commit("map/setVideoToggle", this.accessibilityMode);
      this.$store.dispatch("wagtail/setCurrentPopup", { content: content });
      this.$root.$emit("open-lightbox");
    },
  },
};
</script>

<style></style>
