<template>
  <div class="pano">
    <PanoramaLoading :class="['pano', { panoVRMode: vrMode }]" />
    <div
      :class="['pano', { panoVRMode: vrMode }]"
      ref="panocontainer"
      tabindex="-1"
      name="marzipano-room"
      data-testid="marzipano"
    ></div>
    <PanoramaLoading
      :class="['pano', { panoVRMode: vrMode }]"
      v-if="scenesLoading"
    />
    <slot v-if="scene !== undefined" />
    <div v-if="scene !== undefined && rectConfigEnabled">
      <MarzipanoRectConfigurator
        v-if="scene !== undefined"
        :scene="scene"
        @changed="configuratorChanged"
        tabindex="-1"
        :panoPitch="pitch"
        :panoYaw="yaw"
        :panoFOV="fov"
        @update:yaw="yaw = $event"
        @update:pitch="pitch = $event"
        @update:fov="fov = $event"
      />
      <MarzipanoRectHotspot
        v-if="Object.keys(config).length !== 0"
        :yaw="config.yaw"
        :pitch="config.pitch"
        :rotX="config.rotX"
        :rotY="config.rotY"
        :rotZ="config.rotZ"
        :width="config.width"
        :height="config.height"
        :debug="true"
        :scene="scene"
      />
    </div>
  </div>
</template>

<script>
import Marzipano from "marzipano";
import MarzipanoRectHotspot from "./MarzipanoRectHotspot";
import MarzipanoRectConfigurator from "./MarzipanoRectConfigurator";
import PanoramaLoading from "./PanoramaLoading.vue";

import ResizeSensor from "css-element-queries/src/ResizeSensor";

export default {
  name: "Marzipano",
  props: [
    "multipleScenes",
    "typeOf",
    "tileUrl",
    "data",
    "ready",
    "sasKey",
    "rectConfigEnabled",
    "minYaw",
    "maxYaw",
    "activeScene",
    "activeSceneView",
    "initialViewParameters",
  ],
  data: () => ({
    dialog: true,
    firstClick: true,
    rotx: 0.0,
    roty: 0.0,
    scene: undefined,
    scenesLoading: true,
    drawMode: false,
    config: {},
    keyboardConfig: {},
    rotationIncrement: 5,
    transformIncrement: 1,
    yaw: 0,
    pitch: 0,
    fov: 0,
    vrMode: false,
    lastAlpha: 0,
    lastBeta: 0,
    lastGamma: 0,
    offsetMatrix: null,
  }),
  components: {
    MarzipanoRectHotspot,
    MarzipanoRectConfigurator,
    PanoramaLoading,
  },
  async mounted() {
    await this.$nextTick();

    var viewer = new Marzipano.Viewer(
      // document.getElementById("panorama-container"),
      this.$refs.panocontainer,
      { stage: { progressive: true } }
    );
    if (this.multipleScenes) {
      console.log({ "this.multipleScenes": this.multipleScenes });
      // Initialize all scenes asynchronously and wait for them to be ready
      Promise.all(
        this.multipleScenes.map((item) =>
          this.initScene({
            file: item.panorama.data.file,
            width: item.panorama.data.width,
            levels: item.panorama.data.levels,
            initialViewParameters: item.panorama.data.initialViewParameters,
            typeOf: item.panorama.typeOf,
            minYaw: item.panorama.min_yaw || -181,
            maxYaw: item.panorama.max_yaw || 181,
            tileUrl: item.panorama.tiles.split("?")[0],
            sasKey: item.panorama.sas_key,
            viewer,
            item,
          })
        )
      )
        .then((scenes) => {
          // All scenes are initialized
          console.log({ scenes });
          this.scene = scenes[0].scene; // Assuming `initScene` resolves with an object that has a `scene` property
          this.scene.switchTo();
          this.onRenderComplete();

          // Emit events after all scenes are loaded
          this.$emit("loaded", this.scene);
          this.$emit("view", scenes[0].view);
          this.$emit("initialViewParameters", scenes[0].initialViewParameters);
          this.$emit("scenes", scenes);
        })
        .catch((error) => {
          console.error("Error initializing scenes:", error);
          this.scenesLoading = false; // Hide preloader and possibly handle error
        });
    } else {
      // Handle single scene initialization if needed
      this.initScene({
        file: this.data.file,
        width: this.data.width,
        levels: this.data.levels,
        initialViewParameters: this.data.initialViewParameters,
        typeOf: this.typeOf,
        minYaw: this.minYaw,
        maxYaw: this.maxYaw,
        tileUrl: this.tileUrl,
        sasKey: this.sasKey,
        viewer,
      });
      this.$emit("loaded", this.scene);
      this.onRenderComplete();
    }
    if (this.rectConfigEnabled) {
      this.yaw = viewer.view().yaw().toFixed(5);
      this.pitch = viewer.view().pitch().toFixed(5);
      this.fov = viewer.view().fov().toFixed(5);
      var dragControlMethod = viewer
        .controls()
        .method("mouseViewDrag").instance;
      dragControlMethod.addEventListener("inactive", () => {
        this.yaw = viewer.view().yaw().toFixed(5);
        this.pitch = viewer.view().pitch().toFixed(5);
        this.fov = viewer.view().fov().toFixed(5);
        console.log("Drag End", this.yaw, this.pitch, this.fov);
      });
    }
  },
  watch: {
    ready() {
      if (this.ready === true && this.scene && !this.rectConfigEnabled) {
        this.autoRotateScene();
      }
    },
    activeScene() {
      if (this.activeScene) {
        console.log("asdasdsa", this.activeScene);
        this.activeSceneView.setParameters(this.initialViewParameters);
        this.activeScene.switchTo();
        this.$emit("loaded", this.activeScene);
        this.autoRotateScene();
      }
    },
  },
  methods: {
    autoRotateScene() {
      let autorotate = Marzipano.autorotate({
        yawSpeed: 0.025, // Yaw rotation speed
        targetPitch: 0, // Pitch value to converge to
        targetFov: (80 * Math.PI) / 180, // Fov value to converge to
      });

      this.scene.viewer().startMovement(autorotate);
    },
    onRenderComplete() {
      console.log("Render complete!");
      this.scenesLoading = false;
      this.$emit("walkthroughReady");

      // Implement any action you want to take after the render is complete
    },
    configuratorChanged(data) {
      this.config = data;
    },
    lookToConsole(consoleYaw, consolePitch) {
      var destinationViewParameters = {
        yaw: (consoleYaw * Math.PI) / 60,
        pitch: (consolePitch * Math.PI) / 60,
        fov: (80 * Math.PI) / 180,
      };
      var options = {
        transitionDuration: 2000,
      };
      this.scene.lookTo(destinationViewParameters, options);
    },
    initScene({
      file,
      width,
      levels,
      initialViewParameters,
      typeOf,
      minYaw,
      maxYaw,
      tileUrl,
      sasKey,
      viewer,
      item,
    }) {
      viewer.setIdleMovement(
        5000,
        Marzipano.autorotate({
          yawSpeed: 0.025, // Yaw rotation speed
          targetPitch: 0, // Pitch value to converge to
          targetFov: (80 * Math.PI) / 180, // Fov value to converge to
        })
      );
      console.log(
        this.data,
        this.maxYaw,
        this.minYaw,
        "data from marzipano.vue"
      );
      // console.log(this.typeOf, "typeOf")
      let pano = this.$refs.panocontainer;

      new ResizeSensor(pano, function () {
        viewer.updateSize();
      });

      var view, geometry, source, limiter;

      if (typeOf == "equirect") {
        // Create source.
        source = Marzipano.ImageUrlSource.fromString(file);

        // Create geometry.
        geometry = new Marzipano.EquirectGeometry([{ width: width }]);

        // Create view.
        Marzipano.RectilinearView.limit.yaw(
          Math.PI * (minYaw / 180),
          Math.PI * (maxYaw / 180)
        ),
          //Limiter for Equirect View - Images for Virtual
          (limiter = Marzipano.util.compose(
            Marzipano.RectilinearView.limit.vfov(
              (40 * Math.PI) / 180,
              (120 * Math.PI) / 180
            ),

            Marzipano.RectilinearView.limit.pitch(
              Math.PI * (-80 / 180),
              Math.PI * (80 / 180)
            )
          ));
        view = new Marzipano.RectilinearView(
          { yaw: 10, fov: (Math.PI * 90) / 180 },
          limiter
        );
      } else {
        var urlPrefix = tileUrl;

        source = Marzipano.ImageUrlSource.fromString(
          urlPrefix + "/{z}/{f}/{y}/{x}.jpg?" + sasKey,
          { cubeMapPreviewUrl: urlPrefix + "/preview.jpg?" + sasKey }
        );

        // Create geometry.
        geometry = new Marzipano.CubeGeometry(levels);

        // limiter = Marzipano.RectilinearView.limit.vfov(
        //   Math.PI * (this.minYaw / 180), Math.PI * (this.maxYaw / 180)
        // );

        limiter = Marzipano.util.compose(
          Marzipano.RectilinearView.limit.vfov(
            (40 * Math.PI) / 180,
            (80 * Math.PI) / 180
          ),
          Marzipano.RectilinearView.limit.yaw(
            Math.PI * (minYaw / 180),
            Math.PI * (maxYaw / 180)
          ),
          Marzipano.RectilinearView.limit.pitch(
            Math.PI * (-80 / 180),
            Math.PI * (80 / 180)
          )
        );

        view = new Marzipano.RectilinearView(initialViewParameters, limiter);
      }
      // Autorotate will start after 3s of idle time

      if (this.multipleScenes) {
        const scene = viewer.createScene({
          source: source,
          geometry: geometry,
          view: view,
          pinFirstLevel: false,
        });

        return {
          scene,
          view,
          initialViewParameters,
          item,
        };
      } else if ((this.typeOf = "equirect")) {
        // Create scene.
        this.scene = viewer.createScene({
          source: source,
          geometry: geometry,
          view: view,
          pinFirstLevel: false,
        });

        // Display scene.
        this.scene.switchTo();

        this.autoRotateScene();
      } else {
        // Create scene.
        this.scene = viewer.createScene({
          source: source,
          geometry: geometry,
          view: view,
          pinFirstLevel: false,
        });

        // Display scene.
        this.scene.switchTo();
      }
    },
    toggleVRMode() {
      this.vrMode = !this.vrMode;
      console.log("[VR] Toggle", this.vrMode);
      if (this.vrMode) {
        var neautralviewParams = {
          yaw: (0 * Math.PI) / 180,
          pitch: (0 * Math.PI) / 180,
          fov: (80 * Math.PI) / 180,
        };
        console.log("[VR] Stopping Movement");
        this.scene.viewer().stopMovement();
        this.scene.viewer().setIdleMovement(10000000000000000000000);
        this.scene.viewer().lookTo(neautralviewParams);
        this.setOffsetMatrix(this.lastAlpha, this.lastBeta, this.lastGamma);
        this.enableDeviceMotion();
      } else {
        let autorotate = Marzipano.autorotate({
          yawSpeed: 0.025, // Yaw rotation speed
          targetPitch: 0, // Pitch value to converge to
          targetFov: (80 * Math.PI) / 180, // Fov value to converge to
        });
        this.scene.viewer().setIdleMovement(5000, autorotate);
        this.autoRotateScene();
      }
    },
    // Convert alpha, beta, gamma to a rotation matrix
    getRotationMatrix(alpha, beta, gamma) {
      const _alpha = alpha * (Math.PI / 180);
      const _beta = beta * (Math.PI / 180);
      const _gamma = gamma * (Math.PI / 180);

      const cA = Math.cos(_alpha);
      const sA = Math.sin(_alpha);
      const cB = Math.cos(_beta);
      const sB = Math.sin(_beta);
      const cG = Math.cos(_gamma);
      const sG = Math.sin(_gamma);

      const matrix = [
        cA * cG - sA * sB * sG,
        -cB * sA,
        cA * sG + cG * sA * sB,
        cG * sA + cA * sB * sG,
        cA * cB,
        sA * sG - cA * cG * sB,
        -cB * sG,
        sB,
        cB * cG,
      ];
      return matrix;
    },
    // Multiply two rotation matrices
    multiplyMatrices(a, b) {
      const result = new Array(9);
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
          result[i * 3 + j] =
            a[i * 3 + 0] * b[0 * 3 + j] +
            a[i * 3 + 1] * b[1 * 3 + j] +
            a[i * 3 + 2] * b[2 * 3 + j];
        }
      }
      return result;
    },
    // Extract yaw, pitch, roll from a rotation matrix
    getYawPitchRollFromMatrix(matrix) {
      const pitch = Math.asin(-matrix[6]);
      const yaw = Math.atan2(matrix[3], matrix[0]);
      const roll = Math.atan2(matrix[7], matrix[8]);

      return { yaw, pitch, roll };
    },
    //Update view using derived yaw and pitch
    updateView(alpha, beta, gamma) {
      const currentMatrix = this.getRotationMatrix(alpha, beta, gamma);
      const adjustedMatrix = this.offsetMatrix
        ? this.multiplyMatrices(currentMatrix, this.offsetMatrix)
        : currentMatrix;
      const { yaw, pitch } = this.getYawPitchRollFromMatrix(adjustedMatrix);

      this.scene.view().setYaw(yaw);
      this.scene.view().setPitch(pitch);
    },
    // Enable device orientation and update view
    enableDeviceMotion() {
      if (typeof DeviceOrientationEvent !== "undefined") {
        if (typeof DeviceOrientationEvent.requestPermission === "function") {
          // Safari-specific permission request
          DeviceOrientationEvent.requestPermission()
            .then((permissionState) => {
              if (permissionState === "granted") {
                console.log("Permission granted for device orientation");
                window.addEventListener(
                  "deviceorientation",
                  this.handleDeviceOrientation
                );
              } else {
                console.warn("Permission denied for device orientation");
              }
            })
            .catch((error) => {
              console.error(
                "Error requesting device orientation permission:",
                error
              );
            });
        } else {
          // Add listener directly for browsers that don't require permission
          console.log("Adding device orientation listener");
          window.addEventListener(
            "deviceorientation",
            this.handleDeviceOrientation
          );
        }
      } else {
        console.error(
          "DeviceOrientationEvent is not supported in this browser"
        );
      }
    },

    handleDeviceOrientation(event) {
      console.log("[VR device Motion orientation event trigger]");
      const { alpha, beta, gamma } = event;
      if (alpha !== null && beta !== null && gamma !== null) {
        this.lastAlpha = alpha;
        this.lastBeta = beta;
        this.lastGamma = gamma;
        console.log(
          "[VR device Motion]",
          "Alpha",
          this.lastAlpha,
          "Beta",
          this.lastBeta,
          "Gamma",
          this.lastGamma
        );
        this.updateView(alpha, beta, gamma);
      }
    },

    // Capture the offset rotation matrix
    setOffsetMatrix(alpha, beta, gamma) {
      const initialMatrix = this.getRotationMatrix(alpha, beta, gamma);
      this.offsetMatrix = [
        initialMatrix[0],
        initialMatrix[3],
        initialMatrix[6],
        initialMatrix[1],
        initialMatrix[4],
        initialMatrix[7],
        initialMatrix[2],
        initialMatrix[5],
        initialMatrix[8],
      ];
    },
  },
};
</script>

<style scoped>
.pano {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: visible; /*CHANGED TURNED OFF IMPORTANT CHECK FOR BUGS THOROUGHLY AS TO WHY THIS WAS INITIALLY SET*/
}

.panoVRMode {
  width: 50vw;
  overflow: hidden;
}

.frame {
  width: 1000px;
  height: 1000px;
  background-color: rgba(255, 0, 0, 0.5);
}

.controls {
  position: absolute;
  left: 10px;
  /* right: 10px; */
  top: 10px;
  z-index: 5;
}

.loading-transparent {
  opacity: 0.5;
}
</style>
