<template>
  <LightboxContentLayout
    :accessibilityText="data.accessibility_text"
    :ariaDescription="ariaDescription"
    :captionText="data.caption"
    :accessibilityTextFlag="accessibilityTextFlag"
  >
    <video
      :aria-label="accessibilityLabel"
      class="lightbox-video"
      controls
      ref="videoRef"
      type="video/mp4"
      crossorigin="anonymous"
    >
      <source :src="videoAccessibility" type="video/mp4" />
      <track
        label="English"
        kind="subtitles"
        srclang="en"
        :src="subtitle"
        default
      />
    </video>
  </LightboxContentLayout>
</template>

<script>
import LightboxContentLayout from "./LightboxContentLayout.vue";

export default {
  name: "LightboxVideoCard",
  data: () => ({
    dialog: true,
    isMobile: false,
    windowWidth: window.screen.width,
    isTablet: false,
    isLaptop: false,
  }),
  props: [
    "data",
    "accessibilityTextFlag",
    "accessibilityVideoFlag",
    "accessibility",
    "ariaDescription",
    "currentContent",
  ],
  components: { LightboxContentLayout },
  mounted() {
    console.log("CCA MOUNTED");
    console.log(this.data.subtitle, "data from videolb");
    //const video = this.$refs.videoRef;

    const video = this.$refs.videoRef;
    const source = video.querySelector("source");

    // If the video is from the Journey Planner, autoplay
    console.log("this.$store.state.map.showJourneyVideos", this.$store.state.map.showJourneyVideos)
    if(this.$store.state.map.showJourneyVideos) {
      video.addEventListener("loadeddata", () => {
        if (video.readyState >= 3) {
          video.play();
        }
      });
    } else {
      // Log the initial state of the video and source src
      console.log(
        this.currentContent,
        " CCA current vid content and current sources:",
        source.src, // source element's src
        video.src // video's src (might be empty initially)
      );

      // Pause the video initially
      video.pause();

      // Add the event listener for loadeddata
      video.addEventListener("loadeddata", () => {
        console.log(
          "CCA loadeddata event fired",
          "video src:",
          video.src,
          "source src:",
          source.src
        );

        // At this point, video.src should be fully set
        if (video.readyState >= 3) {
          // Now check if the val matches either the video or source src
          if (
            (this.data.autoplay && this.currentContent === video.src) ||
            (this.data.autoplay && this.currentContent === source.src)
          ) {
            console.log(
              this.currentContent,
              "CCA current vid content and current source MATCH PLAY"
            );
            video.play();
          } else {
            console.log("CCA Video and source do not match the current content");
          }
        }
      });

      // Force a reload to trigger the 'loadeddata' event
      video.load();

    }

    video.addEventListener("ended", (event) => {
      this.$emit("ended");
    });

    for (let i = 0; i < video.textTracks.length; i++) {
      video.textTracks[i].mode = "hidden";
    }

  
  },
  computed: {
    videoToggle() {
      return this.$store.state.map.videoToggle;
    },
    // video() {
    //   if (this.data && this.data.file) return this.data.file;

    //   if (this.data.video2D && this.data.video2D.file)
    //     return this.data.video2D.file;
    //   return undefined;
    // },
    subtitle() {
      if (this.data && this.data.subtitle) return this.data.subtitle;

      if (this.data.video2D && this.data.video2D.subtitle)
        return this.data.video2D.subtitle;
      return undefined;
    },
    videoAccessibility() {
      if (this.accessibilityMode && this.accessibilityVideoFlag && this.data.accessible_video !== null) {
        if (process.env.NODE_ENV === "development") {
          // Branching Videos don't have the full URL but Card Galleries Videos do
          const url = new URL(
            this.data.accessible_video,
            process.env.VUE_APP_BACKEND_URL.slice(0, -1)
          );

          return url;
        }
        return this.data.accessible_video;
      } 
      
      const accessibilityVideo =
        this.data &&
        this.data.video2DAccessibility &&
        this.data.video2DAccessibility.file;

      if (this.videoToggle && accessibilityVideo) {
        if (process.env.NODE_ENV === "development") {
          // Branching Videos don't have the full URL but Card Galleries Videos do
          const url = new URL(
            accessibilityVideo,
            process.env.VUE_APP_BACKEND_URL.slice(0, -1)
          );

          return url;
        }

        return accessibilityVideo;
      }

      if (process.env.NODE_ENV === "development") {
        // Branching Videos don't have the full URL but Card Galleries Videos do
        const url = new URL(
          this.data.file || (this.data.video2D && this.data.video2D.file),
          process.env.VUE_APP_BACKEND_URL.slice(0, -1)
        );
        return url;
      }

      return this.data.file || (this.data.video2D && this.data.video2D.file);
    },
    accessibilityLabel() {
      if (this.data && this.data.file) return this.data.accessibility_text;
      return undefined;
    },
    hasCaption() {
      if (this.data && this.data.caption)
        if (this.data.caption !== undefined) return true;
      return false;
    },
    accessibilityMode() {
      return this.$store.state.wagtail.accessibilityMode;
    },
    hasAccessibleText() {
      if (this.data && this.data.accessibility_text) return true;
      return false;
    },
    videoWidth() {
      if (this.accessibilityTextFlag) return 60 + "%";
      if (
        this.hasAccessibleText &&
        this.hasCaption &&
        this.accessibilityTextFlag == true
      )
        return 70 + "%";
      return 100 + "%";
    },
  },
  watch: {
    accessibilityVideoFlag(newVal){
      console.log("video toggle ", newVal)
    },
    accessibilityVideoFlag(newValue) {
      console.log(newValue, "value changed video");
      const video = this.$refs.videoRef; // Use ref instead of getElementById

      video.pause();
      if (newValue && this.data.accessible_video !== null) {
        video.src = this.data.accessible_video;
      } else{
        video.src = this.data.file;
      }
      video.load();
      video.pause();
      if (video.readyState >= 3) {
        // Now check if the val matches either the video or source src
        if (
          (this.data.autoplay && this.currentContent === video.src) ||
          (this.data.autoplay && this.currentContent === source.src)
        ) {
          console.log(
            this.currentContent,
            "CCA current vid content and current source MATCH PLAY"
          );
          video.play();
        } else {
          console.log("CCA Video and source do not match the current content");
        }
      }
      // video.play();
    },
    currentContent(val) {
      console.log("CCA WATCHER", val);
      const video = this.$refs.videoRef;
      const source = video.querySelector("source");

      // Log the initial state of the video and source src

      // console.log(
      //   val,
      //   " CCA WATCHER current vid content and current sources:",
      //   source.src, // source element's src
      //   video.src // video's src (might be empty initially)
      // );

      // Force a reload to trigger the 'loadeddata' event
      if (val != video.src && val != source.src) {
        // Pause the video initially
        video.pause();
        video.load();
      } else {
        if (this.data.autoplay) {
          video.play();
        }
      }
    },
    videoAccessibility: {
      handler() {
        const video = this.$refs.videoRef;
        console.log("[VA EVENT]");
        //video.load();
        //video.pause();
        //video.play();
        video.addEventListener("ended", (event) => {
          this.$emit("ended");
        });
      },
      deep: true,
    },
  },
  methods: {
    textFlag() {
      console.log("triggered here from image");
      if (this.accessibilityTextFlag == false) {
        this.accessibilityTextFlag = true;
      } else {
        this.accessibilityTextFlag = false;
      }
    },
  },
};
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
}

video {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.lightbox-video {
  width: 100%;
  height: 100%;
}
</style>
